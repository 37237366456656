import { ChainId } from "@pancakeswap/sdk"
import ComingSoonScreen from "views/00_Airdrop/airdrop_screen"

const IndexPage = () => {
    return (
        <ComingSoonScreen />
    )
}
IndexPage.chains = [ChainId.BSC]

export default IndexPage
